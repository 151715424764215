import { z } from 'zod';

export type StepName = z.infer<typeof stepNameSchema>;
export interface StepRouteParams {
	step: StepName;
}

export const stepNameSchema = z.enum([
	'locations',
	'products',
	'quantities',
	'review', // TODO: Can we rename this to shipping?
	'confirmation',
]);
export const stepNames = stepNameSchema._def.values;
export const stepRouteParamsSchema = z.object({
	step: stepNameSchema,
}) satisfies z.ZodType<StepRouteParams> as z.ZodType<StepRouteParams>;
