import { z } from 'zod';

export type OrderSubmissionRequest = z.infer<typeof orderSubmissionRequestSchema>;
export const orderSubmissionRequestSchema = z.object({
	shopPurchaseOrderNumber: z.string().max(35).optional(),
	shopOrderComments: z.string().max(200).optional(),
	shopOrderName: z.string().max(35).optional(),
	shopProjectType: z.string().optional(),
	shopOrderType: z.string().optional(),
	shopOrderDate: z.string().optional(),
});

export type CompleteBulkOrderRequest = Readonly<{
	poNumber: string;
	comments: string;
	isGhostShipment: boolean;
	bypassBudget: boolean;
	orderDate: string;
	acceptedApprovalRequired: boolean;
	orderReason: string;
	orderName: string;
}>;

export type CompleteRequestOptions = {
	orderName?: string;
	orderComments?: string;
	orderNumber?: string;
	holdUntilDate?: DateTime;
	userAcknowledgedApprovalRequired?: boolean;
	isGhostShipment?: boolean;
	bypassBudget?: boolean;
};

export enum CartErrorType {
	quantityUpdateConflict = 'quantityUpdateConflict', // Add/update item quantity conflict
	noActiveProgramsAndOrProducts = 'noActiveProgramsAndOrProducts', // Products are no longer available for selected locations and programs
	commentRequired = 'commentRequired',
	quantitiesRequired = 'quantitiesRequired',
	shippingAddressMismatch = 'shippingAddressMismatch',
	inactiveProgram = 'inactiveProgram',
}

export type CompleteResponse = {
	bulkOrderId?: Guid;
	salesOrderNumber?: string;
	estimatedShipDate?: moment.Moment;
};

export type CompleteBulkOrderResponse = Readonly<{
	completedBulkOrderId: Guid;
	salesOrderNumber: string;
	// NOTE: The word "date" in "Shipdate" must be lowercased to match the API response...
	estimatedShipdate?: DateTime;
	stepToComplete: string;
}>;

export type StaticOrderReason = Readonly<{
	reasonKey: string;
	reasonDescription: string;
}>;
