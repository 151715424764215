import { z } from 'zod';

export interface ProductLine {
	description: string;
	categoryId: number;
	categoryCode: string;
	categoryImageUrl: string;
}

export const productLineSchema = z.object({
	categoryId: z.number(),
	description: z.string(),
	categoryCode: z.string(),
	categoryImageUrl: z.string(),
}) satisfies z.ZodType<ProductLine>;
