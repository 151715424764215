import { z } from 'zod';

import type { ODataPageResult } from '@apple/utils/odata';

import { auditSchema } from '../audit.models';
import { createExcelErrorSchema, createExcelResultSchema } from '../excel.models';
import {
	countryCodeSchema,
	currencyCodeSchema,
	dateTimeSchema,
	emptyOrNullableDateTimeSchema,
} from '../portal/globalization.models';
import { programIdSchema } from '../program/models';
import type { ExcelError, ExcelResult } from '../excel.models';

/**
 * @see Manzanita.Data.Manzanita.ManzanitaProduct
 * @see AppleBrandedPrograms.Catalog.AppleBrandedProduct
 */
export interface Product {
	[key: string]: unknown;
	itemId: string;
	title: string;
	description: string;
	sku: string;
	beginDate: '' | Date | null;
	endDate: '' | Date | null;
	unitOfMeasure: string;
	requiresApproval: boolean;
	showInventory: boolean | null;
	administratorOnly: boolean;
	showInventoryStatus: boolean | null;
	quantityLimit: number | null;
	externalUrl: string | null;
	isBackorderable: boolean;
	comments: string | null;
	imagesJson: string | null;
	createdDate?: Date | null;
	updatedDate?: Date | null;
	rowVersion?: string | null;

	allAssociation: AllAssociation | null;
	categoryMappings: ProductCategory[];
	keywords: ProductKeyword[];
	localizations: ProductLocalization[];
	priceOverrides: PriceOverride[];
	plantAssociations: PlantAssociation[];
	countryAssociations: CountryAssociation[];
	customerGroupAssociations: ProgramAssociation[];
	customerAssociations: CustomerAssociation[];
	customerGroupCountryAssociations: SubProgramAssociation[];
	userRoleAssociations: RoleAssociation[];
	endOfLifeRecords: ProductEndOfLife[];
	newProductIntroductionRecords: NewProductIntroduction[];
	quantityLimits: ProductQuantityLimit[];
}

export interface ProductCategory {
	itemId: string;
	categoryId: number;
}

export interface ProductLocalization {
	itemId: string;
	culture: string;
	title: string;
	description: string;
	updatedDate?: string | Date;
	isDeleted?: boolean;
}

export interface ProductAssociation {
	itemId: string;
	isDeleted: boolean;
	updatedDate?: Date;
}

export interface AllAssociation extends ProductAssociation {}

export interface PlantAssociation extends ProductAssociation {
	plantId: number;
}

export interface CountryAssociation extends ProductAssociation {
	countryCode: string;
}

export interface ProgramAssociation extends ProductAssociation {
	customerGroupId: number;
}

export interface SubProgramAssociation extends ProductAssociation {
	customerGroupId: number;
	countryCode: string;
}

export interface CustomerAssociation extends ProductAssociation {
	customerId: number;
}

export interface RoleAssociation extends ProductAssociation {
	roleId: number;
}

export interface ProductQuantityLimit {
	isDeleted?: boolean;
	updatedDate?: Date | '' | null;
	itemId: string;
	roleId: number;
	maxLimit: number;
	startDate: Date | '' | null;
	endDate: Date | '' | null;
}

export interface Inventory {
	sku: string;
	inventoryItemId: number | null;
	warehouseId: number;
	dateCreated: Date;
	dateUpdated: Date;
	inStockQuantity: number;
	allocatedQuantity: number;
	threshold: number;
	erpAllocatedQuantity: number | null;
	estimatedInStockDate: string | null;
	pendingGoodsReceivedOrder: string | null;
	pendingGoodsReceivedQuantity: number | null;
	enabledInErp: boolean;
	lastOrderDate: Date;
	lastSyncDate: Date;
}

export interface WarehousePrice {
	sku: string;
	updatedDate: Date;
	warehouseLocationId: number;
	commercialPrice: number;
	currencyCode: string;
	activeFrom: Date;
}

export interface PriceOverride {
	clusterKey: number | null;
	warehouseLocationId: number;
	sku: string;
	priceOverride: number | null;
	updatedDate: Date | null;
}

export interface ProductByWarehouse {
	itemId: string;
	sku: string;
	productName: string;
	warehouseCode: string;
	availableQuantity: number;
	customerCount: number;
	unitOfMeasure: string;
	quantityLimit?: number | null;
	commercialPrice: number;
	currencyCode: string;
	commercialPriceUSD?: number | null;
	priceOverride?: number | null;
	visibility: string;
	isEnabledInErp: boolean;
	requiresApproval: boolean;
	isBackorderable: boolean;
	isNewProductIntroductionComplete: boolean;
	isEndOfLifeComplete: boolean;
	isActive: boolean;
	activeDate?: DateTime | null;
	inactiveDate?: DateTime | null;
	hasImages: boolean;
}

export interface ProductImageUploadResponse {
	imageUrl: string;
	previewUrl: string;
}

interface ProductLifecycle {
	warehouseLocationId: number;
	sku: string;
	completedDate: Date | null;
	createdDate?: Date;
	updatedDate?: Date;
	isDeleted?: boolean;
}

export interface NewProductIntroduction extends ProductLifecycle {}
export interface ProductEndOfLife extends ProductLifecycle {
	eolType: EndOfLifeType;
	replacementSku?: string | null;
}

export interface EndOfLifeReplacement {
	warehouseId: number;
	sku: string;
	eolType: EndOfLifeType;
	completedDate: Date | null;
	inStockQuantity: number;
	allocatedQuantity: number;
	thresholdQuantity: number;
}

export interface NewKeyword {
	description: string;
	isDeleted: boolean;
}

export interface Keyword extends NewKeyword {
	keywordId: number;
	updatedDate?: Date;
}

export interface ProductKeyword {
	id?: number | null;
	itemId: string;
	keyWordId: number;
	isDeleted: boolean;
	updatedDate?: Date | null;
}

export interface AdminProductAutocompleteRequest {
	searchText: string;
	partNumbers: string[];
	warehouseLocationId: string | null;
}

export interface ProductAutoCompleteModel {
	id: string;
	displayString: string;
	title: string;
	warehouseCodes: string[];
}

export type ProductListingResponse = ODataPageResult<ProductByWarehouse>;
export type ProductAudit = z.infer<typeof productAuditSchema>;
export type UserRoleAssociation = z.infer<typeof userRoleAssociationSchema>;
export type QuantityLimit = z.infer<typeof quantityLimitSchema>;
export type ProductImages = z.infer<typeof imagesSchema>;

const associationSchema = z.object({
	itemId: z.string(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean(),
}) as z.ZodType<AllAssociation>;
export const allAssociationSchema = associationSchema;
export const plantAssociationSchema = associationSchema.and(
	z.object({ plantId: z.number() }),
) as z.ZodType<PlantAssociation>;
export const countryAssociationSchema = associationSchema.and(
	z.object({ countryCode: countryCodeSchema }),
) as z.ZodType<CountryAssociation>;
export const programAssociationSchema = associationSchema.and(
	z.object({ customerGroupId: programIdSchema }),
) as z.ZodType<ProgramAssociation>;
export const subProgramAssociationSchema = programAssociationSchema.and(
	z.object({ countryCode: countryCodeSchema }),
) as z.ZodType<SubProgramAssociation>;
export const customerAssociationSchema = associationSchema.and(
	z.object({ customerId: z.number() }),
) as z.ZodType<CustomerAssociation>;
export const userRoleAssociationSchema = associationSchema.and(
	z.object({ roleId: z.number() }),
) as z.ZodType<RoleAssociation>;

export enum EndOfLifeType {
	Scrap = 'Scrap',
	Ship = 'Ship',
	SellToZero = 'SellToZero',
}
export const endOfLifeTypeSchema = z.enum([
	'Scrap',
	'Ship',
	'SellToZero',
]) as z.ZodType<EndOfLifeType>;

export const productLifecycleSchema = z.object({
	warehouseLocationId: z.number(),
	sku: z.string(),
	completedDate: dateTimeSchema.nullable(),
	createdDate: dateTimeSchema.optional(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().optional(),
}) as z.ZodType<ProductLifecycle>;

export const endOfLifeSchema = productLifecycleSchema.and(
	z.object({
		eolType: endOfLifeTypeSchema,
		replacementSku: z.string().nullable(),
	}),
) as z.ZodType<ProductEndOfLife>;

export const endOfLifeReplacementSchema = z.object({
	warehouseId: z.number(),
	sku: z.string(),
	eolType: endOfLifeTypeSchema,
	completedDate: dateTimeSchema.nullable(),
	inStockQuantity: z.number(),
	allocatedQuantity: z.number(),
	thresholdQuantity: z.number(),
}) as z.ZodType<EndOfLifeReplacement>;

export const productCategorySchema = z.object({
	itemId: z.string(),
	categoryId: z.number(),
}) satisfies z.ZodType<ProductCategory>;

export const newKeywordSchema = z.object({
	description: z.string(),
	isDeleted: z.boolean(),
}) satisfies z.ZodType<NewKeyword>;

export const keywordSchema = newKeywordSchema.extend({
	keywordId: z.number(),
	updatedDate: dateTimeSchema.optional(),
}) as z.ZodType<Keyword>;

// See https://github.com/colinhacks/zod/discussions/2215#discussioncomment-5356276
const imagesSchema = z.array(
	z.object({
		SmallImageUrl: z.string().url(),
		LargeImageUrl: z.string().url(),
		SortOrder: z.number(),
	}),
);

export const imagesToJsonStringSchema = imagesSchema.transform(x => JSON.stringify(x));
export const jsonStringToImagesSchema = z
	.string()
	.nullable()
	.transform(x => {
		// TODO - can this be made better?
		return JSON.parse(x ?? '[]') as string;
	})
	.pipe(imagesSchema);

export const productKeywordSchema = z.object({
	id: z.number().nullable().optional(),
	itemId: z.string(),
	keyWordId: z.number(),
	isDeleted: z.boolean(),
	updatedDate: dateTimeSchema.optional(),
}) as z.ZodType<ProductKeyword>;

export const warehousePriceSchema = z.object({
	warehouseLocationId: z.number(),
	sku: z.string(),
	commercialPrice: z.number(),
	currencyCode: currencyCodeSchema,
	activeFrom: dateTimeSchema,
	updatedDate: dateTimeSchema,
}) as z.ZodType<WarehousePrice>;

export const priceOverrideSchema = z.object({
	clusterKey: z.number().nullable(),
	warehouseLocationId: z.number(),
	sku: z.string(),
	priceOverride: z.number().nullable(),
	updatedDate: dateTimeSchema.nullable(),
}) as z.ZodType<PriceOverride>;

export const inventorySchema = z.object({
	inventoryItemId: z.number().nullable(),
	sku: z.string(),
	warehouseId: z.number(),
	dateCreated: dateTimeSchema,
	dateUpdated: dateTimeSchema,
	inStockQuantity: z.number(),
	allocatedQuantity: z.number(),
	threshold: z.number(),
	erpAllocatedQuantity: z.number().nullable(),
	estimatedInStockDate: z.string().nullable(),
	pendingGoodsReceivedOrder: z.string().nullable(),
	pendingGoodsReceivedQuantity: z.number().nullable(),
	enabledInErp: z.boolean(),
	lastOrderDate: emptyOrNullableDateTimeSchema,
	lastSyncDate: emptyOrNullableDateTimeSchema,
}) as z.ZodType<Inventory>;

export const productLocalizationSchema = z.object({
	itemId: z.string(),
	culture: z.string(),
	title: z.string(),
	description: z.string(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().optional(),
}) satisfies z.ZodType<ProductLocalization>;

export const quantityLimitSchema = z.object({
	itemId: z.string(),
	roleId: z.number(),
	maxLimit: z.number(),
	startDate: emptyOrNullableDateTimeSchema,
	endDate: emptyOrNullableDateTimeSchema,
	updatedDate: emptyOrNullableDateTimeSchema.optional(),
	isDeleted: z.boolean().optional(),
}) as z.ZodType<ProductQuantityLimit>;

export const productAuditSchema = auditSchema.and(z.object({ itemId: z.string().nullable() }));

export const productSchema = z.object({
	itemId: z.string(),
	title: z.string(),
	description: z.string(),
	sku: z.string(),
	beginDate: emptyOrNullableDateTimeSchema,
	endDate: emptyOrNullableDateTimeSchema,
	unitOfMeasure: z.string(),
	requiresApproval: z.boolean(),
	showInventory: z.boolean().nullable(),
	administratorOnly: z.boolean(),
	showInventoryStatus: z.boolean().nullable(),
	createdDate: dateTimeSchema.nullable().optional(),
	updatedDate: dateTimeSchema.nullable().optional(),
	quantityLimit: z.coerce.number().nullable(),
	externalUrl: z.string().nullable(),
	isBackorderable: z.boolean(),
	comments: z.string().nullable(),
	imagesJson: z.string().nullable(),
	rowVersion: z.string().optional().nullable(),

	allAssociation: allAssociationSchema.nullable(),
	categoryMappings: z.array(productCategorySchema),
	keywords: z.array(productKeywordSchema),
	localizations: z.array(productLocalizationSchema),
	priceOverrides: z.array(priceOverrideSchema),
	plantAssociations: z.array(plantAssociationSchema),
	countryAssociations: z.array(countryAssociationSchema),
	customerGroupAssociations: z.array(programAssociationSchema),
	customerAssociations: z.array(customerAssociationSchema),
	customerGroupCountryAssociations: z.array(subProgramAssociationSchema),
	userRoleAssociations: z.array(userRoleAssociationSchema),
	endOfLifeRecords: z.array(endOfLifeSchema),
	newProductIntroductionRecords: z.array(productLifecycleSchema),

	quantityLimits: z.array(quantityLimitSchema),
}) as z.ZodType<Product>;

export const AdminProductAutocompleteRequestSchema = z.object({
	searchText: z.string(),
	partNumbers: z.array(z.string()),
	warehouseLocationId: z.string().nullable(),
}) satisfies z.ZodType<AdminProductAutocompleteRequest>;

export const ProductAutoCompleteModelSchema = z.object({
	id: z.string(),
	displayString: z.string(),
	title: z.string(),
	warehouseCodes: z.array(z.string()),
}) satisfies z.ZodType<ProductAutoCompleteModel>;

export type ProductExcelResult = ExcelResult<Product, 'itemId', ProductExcelResultError>;
export type ProductExcelResultError = ExcelError<Product, 'itemId'>;

const excelProductKeySchema = z.object({ itemId: z.string() });

export const productExcelErrorSchema = createExcelErrorSchema(
	excelProductKeySchema,
) satisfies z.ZodType<ProductExcelResultError>;

export const productExcelResultSchema = createExcelResultSchema(
	productSchema,
	productExcelErrorSchema,
	excelProductKeySchema.shape.itemId,
) satisfies z.ZodType<ProductExcelResult>;
