import axios from 'axios';

import { projectSchema } from '../project/models';
import type { Project, ProjectId, SaveProjectRequest } from '../project/models';

/** Service wrapper for AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.ProjectManagementApiController */

export async function getAllProjects(signal?: AbortSignal): Promise<Project[]> {
	const response = await axios.get<Project[]>('/api/management/projects', { signal });

	return response.data;
}

export async function saveProject(request: SaveProjectRequest): Promise<void> {
	await axios.put('/api/management/projects', request);
}

export async function deleteProject(projectId: ProjectId): Promise<void> {
	await axios.delete(`/api/management/projects/${projectId}`);
}

export async function getProjects(): Promise<Project[]> {
	const data = (await axios.get<unknown>('/api/management/projects')).data;
	return projectSchema.array().parse(data);
}
