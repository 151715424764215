import { z } from 'zod';

import { dateTimeSchema } from '@apple/api/portal/globalization.models';

export interface LocationAccessRequest {
	userId: number;
	username: string;
	firstName: string;
	lastName: string;
	role: string;
	requests: number;
	date?: Date | string | null;
}

export interface LocationUser {
	id: number;
	email: string;
	username: string;
	firstName: string;
	lastName: string;
}

export const approvalAccessRequestsListItemSchema = z.object({
	userId: z.number(),
	username: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	role: z.string(),
	requests: z.number(),
	date: dateTimeSchema,
}) satisfies z.ZodType<LocationAccessRequest>;

export const locationUserSchema = z.object({
	id: z.number(),
	email: z.string().email(),
	username: z.string().min(1),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
}) satisfies z.ZodType<LocationUser>;
