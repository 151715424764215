import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLogger } from 'loglevel';

import { deleteSavedBulkOrder, saveBulkOrder, setActiveSavedBulkOrder } from '@apple/api/cart';
import { cartQueryKeys, cartQueryOptions } from '@apple/api/cart/queries';
import type { SaveBulkOrderRequest } from '@apple/api/order/bulk-import.models';

const log = getLogger('useSavedCarts');

export function useSavedCarts() {
	const queryClient = useQueryClient();
	const savedCarts = useQuery(cartQueryOptions.saved);

	const activeCart = useMutation({
		mutationKey: ['cart', 'set-active'],
		mutationFn: async (cartId: Guid) => {
			log.debug('Setting cart as active', cartId);
			await setActiveSavedBulkOrder(cartId);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: cartQueryKeys.cartId,
			});
		},
	});

	const saveCart = useMutation({
		mutationKey: ['cart', 'save'],
		mutationFn: async (request: SaveBulkOrderRequest) => {
			log.debug('Saving cart', request);
			await saveBulkOrder(request);
		},
		onSuccess: async () => {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: cartQueryKeys.cartId,
				}),
				queryClient.invalidateQueries({
					queryKey: cartQueryKeys.saved,
				}),
			]);
		},
	});

	const deleteCart = useMutation({
		mutationKey: ['cart', 'delete'],
		mutationFn: async (cartId: Guid) => {
			log.debug('Deleting cart', cartId);
			await deleteSavedBulkOrder(cartId);
		},
		onSuccess: async () => {
			await Promise.all([
				queryClient.invalidateQueries({
					queryKey: cartQueryKeys.cartId,
				}),
				queryClient.invalidateQueries({
					queryKey: cartQueryKeys.saved,
				}),
			]);
		},
	});

	return {
		savedCarts,
		activeCart,
		saveCart,
		deleteCart,
	};
}
