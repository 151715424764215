import { Menu, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

/* eslint-disable-next-line */
export type ToggleColorSchemeMenuItemProps = {};

export function ToggleColorSchemeMenuItem(props: ToggleColorSchemeMenuItemProps) {
	const { toggleColorScheme } = useMantineColorScheme();
	const currentColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	// <IconSwitchHorizontal
	// 	style={{ width: rem(16), height: rem(16) }}
	// 	stroke={1.5}
	// />
	return (
		<Menu.Item
			onClick={toggleColorScheme}
			leftSection={
				currentColorScheme === 'light' ? (
					<IconMoon size={16} stroke={1.5} />
				) : (
					<IconSun size={16} stroke={1.5} />
				)
			}
		>
			{currentColorScheme === 'light' ? 'Switch to dark theme' : 'Switch to light theme'}
		</Menu.Item>
	);
}
