import { memo } from 'react';
import { Box, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import type { MantineBreakpoint } from '@mantine/core';
import type { ToOptions } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';

import { NavLink } from '@apple/components/Link';
import type { IconElement } from '@apple/assets/icons';
import type { MenuItem, MenuItemGroup } from '@apple/layouts/shared/menu-item';

type MenuSize = 'sm' | 'md' | 'lg';

export const SidebarMenu = memo(
	({
		size = 'sm',
		sidebarCollapseBreakpoint,
		globalMenuItems = [],
		sidebarMenuItems = [],
	}: {
		size?: MenuSize;
		sidebarCollapseBreakpoint: MantineBreakpoint;
		globalMenuItems: MenuItem[];
		sidebarMenuItems: MenuItemGroup[];
	}) => {
		return (
			<Stack gap={rem(16)} align='stretch' justify='flex-start'>
				<SidebarMenuSection title='App' hiddenFrom={sidebarCollapseBreakpoint}>
					{globalMenuItems.map(({ icon, label, to }, linkIndex) => (
						<SidebarMenuLink
							key={linkIndex}
							size={size}
							icon={icon}
							label={label}
							to={to}
						/>
					))}
				</SidebarMenuSection>

				{sidebarMenuItems.map((group, groupIndex) =>
					group.items.length <= 0 ? null : (
						<SidebarMenuSection key={groupIndex} title={group.label}>
							{group.items.map(({ icon, label, to }, linkIndex) => (
								<SidebarMenuLink
									key={linkIndex}
									size={size}
									icon={icon}
									label={label}
									to={to}
								/>
							))}
						</SidebarMenuSection>
					),
				)}
			</Stack>
		);
	},
);

SidebarMenu.displayName = 'SidebarMenu';

const SidebarMenuSection = memo(
	({
		title,
		children,
		...props
	}: PropsWithChildren<{
		title?: string;
		visibleFrom?: MantineBreakpoint;
		hiddenFrom?: MantineBreakpoint;
	}>) => {
		return (
			<Stack gap={0} {...props}>
				<Text size='xs' fw={700} c='dimmed' pl='sm' hidden={!title}>
					{title}
				</Text>
				<Box>{children}</Box>
			</Stack>
		);
	},
);

SidebarMenuSection.displayName = 'SidebarMenuSection';

type SidebarMenuLinkProps = {
	size: MenuSize;
	label: string;
	icon: IconElement;
} & ToOptions;

const SidebarMenuLink = memo(
	({ size, label, icon: IconComponent, ...toProps }: SidebarMenuLinkProps) => {
		const {
			other: { controlAccentColor, primarySidebar },
		} = useMantineTheme();

		return (
			<NavLink
				{...toProps}
				label={label}
				leftSection={
					<IconComponent
						size={primarySidebar.iconSize[size]}
						width={primarySidebar.iconSize[size]}
						height={primarySidebar.iconSize[size]}
						color={controlAccentColor}
					/>
				}
				variant='filled'
				defaultOpened={true}
				style={{ borderRadius: rem(6) }}
				fz={primarySidebar.textSize[size]}
				fw='500'
				h={primarySidebar.rowHeight[size]}
				preload={false}
			/>
		);
	},
);

SidebarMenuLink.displayName = 'SidebarMenuLink';
