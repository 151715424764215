import { getPlants } from '.';
import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getEolReplacementRecords, getInventory, getPrices } from '../product/management';

export const productInventoryQueryOptions = (itemId: string, enabled: boolean = true) =>
	queryOptions({
		queryKey: ['product-inventory'],
		queryFn: () => getInventory(itemId),
		staleTime: 15 * 60 * 1000,
		enabled,
	});

export const productPricesQueryOptions = (itemId: string, enabled: boolean = true) =>
	queryOptions({
		queryKey: ['product-prices'],
		queryFn: () => getPrices(itemId),
		staleTime: Infinity,
		enabled,
	});

export const plantsQueryOptions = queryOptions({
	queryKey: ['plants'],
	queryFn: () => getPlants(),
	placeholderData: keepPreviousData,
	staleTime: Infinity,
});

export const eolReplacementRecordsQueryOptions = (itemId: string, enabled: boolean = true) =>
	queryOptions({
		queryKey: ['eol-replacement-records'],
		queryFn: () => getEolReplacementRecords(itemId),
		staleTime: 15 * 60 * 1000,
		enabled,
	});
