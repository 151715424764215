import { useState } from 'react';
import { ActionIcon, Anchor, Button, Group, Modal, Stack, Text } from '@mantine/core';
import moment from 'moment';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/utils/localization';
import type { BulkOrderInfo } from '@apple/api/order/bulk-import.models';

export type SavedCartsListProps = {
	savedCarts: BulkOrderInfo[] | undefined;
	onClick: (bulkOrderId: string) => void;
	onRemoveClick: (bulkOrderId: string) => void;
};

export function SavedCartsList({ savedCarts, onClick, onRemoveClick }: SavedCartsListProps) {
	const { t } = useTranslation('shop');
	const [bulkOrderIdValue, setBulkOrderIdValue] = useState('');

	const [switchModalOpened, setSwitchModalOpened] = useState(false);
	const [removeModalOpened, setRemoveModalOpened] = useState(false);

	const handleSwitchModalClose = () => setSwitchModalOpened(false);
	const handleRemoveModalClose = () => setRemoveModalOpened(false);

	const savedOrdersDates =
		savedCarts &&
		savedCarts.map(x => ({
			bulkOrderId: x.bulkOrderId,
			date: moment(x.dateSaved).format('L LT'),
		}));

	function handleRemoveClick(bulkOrderId: string) {
		setBulkOrderIdValue(bulkOrderId);
		setRemoveModalOpened(true);
	}

	const handleCartItemClick = (bulkOrderId: string) => {
		setBulkOrderIdValue(bulkOrderId);
		setSwitchModalOpened(true);
	};

	const handleModalSwitchClick = (bulkOrderId: string) => {
		onClick(bulkOrderId);
		setSwitchModalOpened(false);
	};

	const handleModalRemoveClick = (bulkOrderId: string) => {
		onRemoveClick(bulkOrderId);
		setRemoveModalOpened(false);
	};

	return (
		<>
			<Modal
				opened={switchModalOpened}
				onClose={handleSwitchModalClose}
				title={t('cart.switchModal.title')}
				centered
			>
				<Stack>
					<Text>{t('cart.switchModal.description')}</Text>
					<Group justify='flex-end' wrap='nowrap'>
						<Button onClick={() => handleModalSwitchClick(bulkOrderIdValue)}>
							{t('cart.switchModal.continue')}
						</Button>
					</Group>
				</Stack>
			</Modal>
			<Modal
				opened={removeModalOpened}
				onClose={handleRemoveModalClose}
				title={t('cart.removeModal.title')}
				centered
			>
				<Stack>
					<Text>{t('cart.removeModal.description')}</Text>
					<Group justify='flex-end' wrap='nowrap'>
						<Button onClick={() => handleModalRemoveClick(bulkOrderIdValue)}>
							{t('cart.removeModal.continue')}
						</Button>
					</Group>
				</Stack>
			</Modal>
			<Group ml={15}>
				<Text ml={35} mt='md'>
					{t('cart.labels.savedOrders')}
				</Text>
				{savedOrdersDates &&
					savedOrdersDates.map(({ bulkOrderId, date }) => (
						<Group key={bulkOrderId} wrap='nowrap' justify='space-between'>
							<Anchor onClick={() => handleCartItemClick(bulkOrderId)}>
								<Text size='sm'>{date}</Text>
							</Anchor>
							<ActionIcon
								variant='transparent'
								onClick={() => handleRemoveClick(bulkOrderId)}
								data-testid='removeSavedCart'
							>
								<icons.Trash />
							</ActionIcon>
						</Group>
					))}
			</Group>
		</>
	);
}
