import { ActionIcon, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

export function ToggleColorSchemeButton() {
	const { setColorScheme } = useMantineColorScheme();
	const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

	return (
		<ActionIcon
			onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
			variant='transparent'
			color='unset'
			size='md'
			aria-label='Toggle color scheme'
		>
			{computedColorScheme === 'light' ? (
				<IconMoon size={16} stroke={1.5} />
			) : (
				<IconSun size={16} stroke={1.5} />
			)}
		</ActionIcon>
	);
}
