import { z } from 'zod';

export type OptionKeyValue = {
	Key: string;
	Value: string;
};

export type TimeZoneOption = OptionKeyValue;
export type CountryOption = OptionKeyValue;
export type CurrencyOption = OptionKeyValue;
export type LanguageOption = OptionKeyValue;
export type DateTime = z.infer<typeof dateTimeSchema>;
export type EmptyOrNullableDateTime = z.infer<typeof emptyOrNullableDateTimeSchema>;
export type CountryWithCultures = z.infer<typeof countryWithCultures>;

// Empty string literal in nullableDateTimeSchema is a workaround for emptying a controlled date input
export const dateTimeSchema = z.union([z.date(), z.string().datetime().pipe(z.coerce.date())]);
export const emptyOrNullableDateTimeSchema = z.union([z.literal(''), dateTimeSchema.nullable()]);
// TODO - will coerce be correct?
export const localDateTimeSchema = z.union([
	z.date(),
	z.string().datetime({ local: true }).pipe(z.coerce.date()),
]);
export const emptyOrNullableLocalDateTimeSchema = z.union([
	z.literal(''),
	localDateTimeSchema.nullable(),
]);
export const dateTimeOffsetSchema = z.string().datetime({ offset: true }).pipe(z.coerce.date());

export const currencyCodeSchema = z.string();
export const countryCodeSchema = z.string();
export const cultureCodeSchema = z.string();

export const countryWithCultures = z.object({
	code: countryCodeSchema,
	name: z.string(),
	cultures: z.array(cultureCodeSchema),
});

export interface Currency {
	isoCode: string;
	englishName: string;
	localSymbol: string;
}

export const currencySchema = z.object({
	isoCode: currencyCodeSchema,
	englishName: z.string(),
	localSymbol: z.string(),
}) satisfies z.ZodType<Currency>;

export const DEFAULT_CULTURE = 'en-US';
