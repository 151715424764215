import { z } from 'zod';

import { dateTimeSchema } from './portal/globalization.models';

export type Audit = z.infer<typeof auditSchema>;

export const auditSchema = z.object({
	correlationId: z.string(),
	dateTime: dateTimeSchema,
	username: z.string(),
	event: z.string(),
	userComments: z.string().nullable(),
});
