import { Button, Center, Stack, Text, Title } from '@mantine/core';
import { Link } from '@tanstack/react-router';

import { useTranslation } from '@apple/utils/localization';

export function NotFoundPage() {
	const { t } = useTranslation('common');

	return (
		<Center pt={28}>
			<Stack align='center'>
				<Title>{t('error.notFound.title')}</Title>
				<Text>{t('error.notFound.message')}</Text>
				{/* FIXME: This button is redirecting to home but says back */}
				<Button variant='outline' component={Link} to='/'>
					{t('buttons.back')}
				</Button>
			</Stack>
		</Center>
	);
}
