import { useCallback } from 'react';
import { ActionIcon, Badge, Box, Divider, Group, Menu } from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import type { MantineColor, StyleProp } from '@mantine/core';

import { icons } from '@apple/assets';
import { CartActions } from '@apple/components/Carts/CartActions';
import { SavedCartsList } from '@apple/components/Carts/SavedCartList';
import { useCartIdContext } from '@apple/contexts/cart';
import { useCartItemCount } from '@apple/hooks/cart/useCartItemCount';
import { useResetCart } from '@apple/hooks/cart/useResetCart';
import { useSavedCarts } from '@apple/hooks/cart/useSavedCarts';

export type CartMenuProps = {
	c: StyleProp<MantineColor>;
};

export function CartMenu({ c }: CartMenuProps) {
	const navigate = useNavigate();
	const cartId = useCartIdContext();
	const { savedCarts, activeCart, deleteCart } = useSavedCarts();
	const resetCart = useResetCart();
	const cartItemCount = useCartItemCount(cartId);

	const handleCreateNewCart = useCallback(() => {
		resetCart.mutate(undefined, { onSuccess: () => void navigate({ to: '/cart' }) });
	}, [resetCart, navigate]);

	const handleLoadCart = useCallback(
		(cartId: Guid) => {
			activeCart.mutate(cartId, {
				onSuccess: () => void navigate({ to: '/cart/$step', params: { step: 'review' } }),
			});
		},
		[activeCart, navigate],
	);

	const handleViewCart = () => {
		return void navigate({ to: '/cart/$step', params: { step: 'review' } });
	};

	return (
		<Box style={{ display: 'flex', alignItems: 'center' }}>
			<Menu
				shadow='lg'
				width={200}
				transitionProps={{ transition: 'pop', duration: 150 }}
				data-testid='shopping-cart'
			>
				<Menu.Target>
					<ActionIcon
						variant='transparent'
						c={c}
						loading={savedCarts.isLoading || cartItemCount.isLoading}
						children={<icons.ShoppingBag size={16} />}
					/>
				</Menu.Target>
				<Menu.Dropdown>
					<CartActions
						onCreateCart={handleCreateNewCart}
						onViewCart={handleViewCart}
						itemsCount={cartItemCount.data ?? 0}
					/>
					<Group justify='center'>
						<Divider w={150} mt='lg' />
					</Group>

					<Box>
						<SavedCartsList
							savedCarts={savedCarts.data ?? []}
							onClick={handleLoadCart}
							onRemoveClick={deleteCart.mutate}
						/>
					</Box>
				</Menu.Dropdown>
			</Menu>
			<Box ml='auto'>
				<Badge size='sm' mb='xs' mr='0' circle>
					{cartItemCount.data ?? 0}
				</Badge>
			</Box>
		</Box>
	);
}
