import { memo } from 'react';
import { ActionIcon, Group, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type { GroupProps, MantineColor, StyleProp } from '@mantine/core';

import { icons } from '@apple/assets';
import { AvatarMenu } from '@apple/components/AvatarMenu';
import { CartMenu } from '@apple/components/Carts/CartMenu';
// import { SpotlightSearchButton } from '@apple/components/SpotlightSearchButton';
import { ToggleColorSchemeButton } from '@apple/components/ToggleColorSchemeButton';
import { useAuthContext } from '@apple/hooks/useAuthContext';

function _TopbarUserMenu({
	variant,
	...groupProps
}: Omit<GroupProps, 'c'> & { variant: 'light' | 'dark' }) {
	const { authenticated } = useAuthContext();
	const { white, black } = useMantineTheme();

	const fontColor = variant === 'dark' ? black : white;

	return (
		<Group wrap='nowrap' gap={0} {...groupProps}>
			{/* {authenticated && <SpotlightSearchButton />} */}
			{authenticated && <CartMenu c={fontColor} />}
			{!authenticated && <ToggleColorSchemeButton />}
			{authenticated && <Notifications c={fontColor} />}
			{authenticated && <AvatarMenu variant={variant === 'dark' ? 'light' : 'dark'} />}
		</Group>
	);
}

export const TopbarUserMenu = memo(_TopbarUserMenu);

function Notifications({ c }: { c: StyleProp<MantineColor> }) {
	function handleClick() {
		notifications.show({
			title: 'Notifications',
			message: 'Not implemented yet',
		});
	}

	// TODO: Fix the icon color
	return (
		<ActionIcon variant='transparent' onClick={handleClick} c={c}>
			<icons.Notifications />
		</ActionIcon>
	);
}
