import { useState } from 'react';
import { Avatar, Group, Menu, rem, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useRouter } from '@tanstack/react-router';
import { clsx as cx } from 'clsx';

import { icons } from '@apple/assets';
import { ToggleColorSchemeMenuItem } from '@apple/components/ToggleColorSchemeMenuItem';
import { useAuthContext } from '@apple/hooks/useAuthContext';
import { useTranslation } from '@apple/utils/localization';

import classes from './AvatarMenu.module.css';

export function AvatarMenu({
	fullWidth = false,
	fullName = false,
	variant,
}: {
	fullWidth?: boolean;
	fullName?: boolean;
	variant: 'light' | 'dark';
}) {
	const { t } = useTranslation('common');
	const auth = useAuthContext();
	const router = useRouter();
	const [isOpen, setIsOpen] = useState(false);
	const theme = useMantineTheme();

	if (!auth.profile) {
		return null;
	}

	const fullDisplayName = `${auth.profile.firstName} ${auth.profile.lastName}`;

	return (
		<Menu
			width={rem(260)}
			position='bottom-end'
			transitionProps={{ transition: 'pop-top-right' }}
			onClose={() => setIsOpen(false)}
			onOpen={() => setIsOpen(true)}
			closeOnEscape
			closeOnClickOutside
			withinPortal
		>
			<Menu.Target>
				<UnstyledButton
					w={fullWidth ? '100%' : undefined}
					p={rem(3)}
					className={cx(classes.user, {
						[variant === 'light' ? classes.activeLightBg! : classes.activeDarkBg!]:
							isOpen,
					})}
				>
					<Group gap={5} wrap='nowrap'>
						<Avatar
							title={fullDisplayName}
							alt={fullDisplayName}
							radius='xl'
							color={variant === 'light' ? theme.black : theme.white}
							size={rem(24)}
						/>
						<Text fw={500} size='xs' lh={1} style={{ textWrap: 'nowrap' }}>
							{fullName ? fullDisplayName : auth.profile.firstName}
						</Text>
						<icons.ChevronDown
							style={{ width: rem(12), height: rem(12) }}
							stroke={1.5}
						/>
					</Group>
				</UnstyledButton>
			</Menu.Target>

			<Menu.Dropdown className={classes.menuDropdown}>
				<Menu.Label>{t('shell.settings')}</Menu.Label>
				<Menu.Item
					onClick={() => void router.navigate({ to: '/profile' })}
					leftSection={
						<icons.ProfileEditIcon
							style={{ width: rem(16), height: rem(16) }}
							strokeWidth={1.5}
						/>
					}
				>
					{t('shell.accountSettings')}
				</Menu.Item>
				<Menu.Item
					onClick={() => void router.navigate({ to: '/address-book' })}
					leftSection={
						<icons.Book style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					}
				>
					{t('shell.addressBook')}
				</Menu.Item>
				<ToggleColorSchemeMenuItem />
				<Menu.Divider />
				<Menu.Item
					onClick={() => void router.navigate({ to: '/logout' })}
					leftSection={
						<icons.Logout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					}
				>
					{t('shell.logout')}
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
