import { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { cartQueryOptions } from '@apple/api/cart/queries';
import { LoadingOverlay } from '@apple/components/LoadingOverlay';
import { useAuthContext } from '@apple/hooks/useAuthContext';

const CartIdContext = createContext<Guid | null>(null);

export function CartIdProvider({ children }: { children: React.ReactNode }) {
	const auth = useAuthContext();
	const cartId = useQuery(cartQueryOptions.cartId(auth.authenticated));

	return (
		<CartIdContext.Provider value={cartId.data?.bulkOrderId ?? null}>
			<LoadingOverlay visible={cartId.isLoading} />
			{cartId.isLoading ? null : children}
		</CartIdContext.Provider>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCartIdContext() {
	return useContext(CartIdContext);
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCartId() {
	const ctx = useCartIdContext();

	if (ctx === null) {
		throw new Error('CartIdProvider component was not found in the tree');
	}

	return ctx;
}
