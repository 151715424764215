import axios, { AxiosError, AxiosHeaders } from 'axios';
// import axiosBetterStacktrace from 'axios-better-stacktrace';
import type { AxiosInstance, AxiosResponse } from 'axios';

import { getEnv } from '@apple/config/env';
import { parseServerError } from '@apple/utils/validation/server';

export function configAxios() {
	axios.defaults.baseURL = getEnv().APPLE_API_URL;
	axios.defaults.headers.common['x-use-camel-case'] = 'true';
	// https://github.com/axios/axios/issues/1322
	// https://github.com/aspnet/AspNetKatana/blob/43996b47015ca0c0ad12cdb6c87a017534eec620/src/Microsoft.Owin.Security.Cookies/Provider/DefaultBehavior.cs#L50
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	// Forces sending cookies along with requests that are for a different domain than the site
	axios.defaults.withCredentials = true;
	// axiosBetterStacktrace(axios);
	// setupUnauthorizedRedirect(axios);
	setupXsrfHandler(axios);
	setupServerValidationHandler(axios);
}

// export function setupUnauthorizedRedirect(instance: AxiosInstance): void {
// 	instance.interceptors.response.use(undefined, async (error: unknown) => {
// 		console.log('router:', router);

// 		if (
// 			isAxiosError(error) &&
// 			error.response?.status === 401 &&
// 			router.latestLocation.pathname !== '/login'
// 		) {
// 			await router.options.context.auth.logout();
// 			await router.navigate({
// 				to: '/login',
// 				search: { returnUrl: router.latestLocation.pathname },
// 			});
// 		}
// 	});
// }

export function setupXsrfHandler(instance: AxiosInstance): void {
	const xsrfRequestHeaderName = 'RequestVerificationToken';

	function updateXsrfToken(response: AxiosResponse) {
		const headers = response.headers;
		if (headers instanceof AxiosHeaders) {
			const token = headers.get(xsrfRequestHeaderName.toLowerCase());
			if (
				token !== undefined &&
				axios.defaults.headers.common[xsrfRequestHeaderName] !== token
			) {
				axios.defaults.headers.common[xsrfRequestHeaderName] = token;
			}
		}

		return response;
	}

	instance.interceptors.response.use(
		success => updateXsrfToken(success),
		error => {
			if (error instanceof AxiosError && error.response !== undefined) {
				updateXsrfToken(error.response);
			}
			throw error;
		},
	);
}

export function setupServerValidationHandler(instance: AxiosInstance): void {
	instance.interceptors.response.use(
		success => success,
		(error: unknown) => {
			throw parseServerError(error);
		},
	);
}
