import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { Store } from '@tanstack/store';
import { isEqual } from 'lodash';
import { getLogger } from 'loglevel';
import type { QueryClient } from '@tanstack/react-query';

import { stepNameSchema } from '@apple/routes/_authed/-steps/types';
import type { StepName } from '@apple/routes/_authed/-steps/types';

const log = getLogger('steps');

export interface StepDef {
	hidden?: boolean;
	isCompleteStep?: boolean;
	validator?: StepValidatorFn;
}

export type StepValidatorFn = (ctx: { queryClient: QueryClient }) => Promise<boolean>;

export interface StepsState {
	currentStep: StepName;
	currentStepIndex: number;
	valid: Record<StepName, boolean>;
}

export interface StepsContext {
	// State
	//state: StepsState;
	steps: StepName[];
	revalidate: () => Promise<void>;

	// Hooks
	useStepNavigation: () => {
		navigating: boolean;
		gotoStep: (step: StepName | number) => void;
		gotoNextStep: () => void;
		gotoPreviousStep: () => void;
	};
	useStepValidities: () => Record<StepName, boolean>;
	useStepValidity: (stepName: StepName) => boolean;
	useStep: (stepName: StepName) => StepHook;
	getFirstInvalidStep: () => { index: number; name: StepName };
	isStepValid: (stepName: StepName) => boolean;
}

export interface StepHook {
	name: StepName;
	index: number;
	isConfirmationStep: boolean;
	valid: boolean;
}

export interface StepsContextOptions {
	queryClient: QueryClient;
	steps: Record<StepName, StepDef>;
	currentStep: StepName;
}

export async function createStepsContext({
	queryClient,
	steps: stepDefs,
	currentStep,
}: StepsContextOptions): Promise<StepsContext> {
	const steps = Object.entries(stepDefs)
		.filter(x => !x[1].hidden)
		.map(x => x[0]) as StepName[];

	log.debug('Creating steps context with steps:', steps);
	const store = new Store<StepsState>({
		currentStep,
		currentStepIndex: steps.indexOf(currentStep),
		valid: await validate(),
	});

	log.debug('Initial steps state:', store.state);

	async function validate(): Promise<Record<StepName, boolean>> {
		// TODO: This could be memoizes since it gets called a lot more often than it changes
		const result = await steps.reduce<Promise<Record<StepName, boolean>>>(
			async (accPromise, stepName) => {
				const acc = await accPromise;
				const stepDef = stepDefs[stepName];

				if (stepDef.hidden || stepDef.isCompleteStep) {
					acc[stepName] = true;
					return acc;
				}

				if (!stepDef.validator) {
					log.warn('No validator found for step:', stepName);
					acc[stepName] = true;
					return acc;
				}

				acc[stepName] = await stepDef.validator({ queryClient });
				return acc;
			},
			Promise.resolve({} as Record<StepName, boolean>),
		);
		//log.debug('calculated steps validity:', result);
		return result;
	}

	async function revalidate() {
		const updatedValid = await validate();
		if (isEqual(updatedValid, store.state.valid)) {
			// log.debug('Skipping steps validity update because it has not changed.');
			return;
		}

		log.debug('Updating steps validity:', updatedValid);

		store.setState(prev => ({
			...prev,
			valid: updatedValid,
		}));
	}

	function getLastValidStep() {
		let lastValidStep = { index: 0, name: getStepName(0) };

		for (let i = 0; i < steps.length; i++) {
			const stepName = getStepName(i);

			// Exclude the complete step
			if (stepDefs[stepName].isCompleteStep || !store.state.valid[stepName]) {
				break;
			}

			lastValidStep = { index: i, name: stepName };
		}

		log.debug('Last valid step:', lastValidStep);
		return lastValidStep;
	}

	function isStepValid(stepName: StepName) {
		return store.state.valid[stepName];
	}

	function getFirstInvalidStep() {
		const lastValidStep = getLastValidStep();
		// Default to the first step if no steps are valid
		if (!isStepValid(lastValidStep.name)) {
			return lastValidStep;
		}

		const nextStepIndex = lastValidStep.index + 1;
		if (nextStepIndex >= steps.length) {
			return lastValidStep;
		}

		const nextStepName = getStepName(nextStepIndex);
		return {
			index: nextStepIndex,
			name: nextStepName,
		};
	}

	function getStepName(index: number): StepName {
		return stepNameSchema.parse(steps[index]);
	}

	function useStepNavigation() {
		const navigate = useNavigate();

		const gotoStepMutation = useMutation({
			mutationKey: ['cart', 'steps', 'goto'],
			mutationFn: async (step: StepName | number) => {
				const stepIndex = typeof step === 'number' ? step : steps.indexOf(step);
				if (stepIndex === -1) {
					log.warn('Cannot go to step because it is not defined:', step);
					return;
				}

				const stepName = getStepName(stepIndex);
				const stepDef = stepDefs[stepName];
				if (stepDef.hidden) {
					log.warn('Cannot go to step because it is hidden:', step);
					return;
				}

				if (stepDef.isCompleteStep) {
					await navigate({
						to: '/cart/$step',
						params: {
							step: stepName,
						},
					});
					return;
				}

				const firstInvalidStep = getFirstInvalidStep();
				if (firstInvalidStep.index < stepIndex) {
					log.warn('Cannot go to step because it, or a prior step, is invalid:', step);
					return;
				}

				log.debug('Navigating to step:', stepName);
				store.setState(curr => ({
					...curr,
					currentStep: stepName,
					currentStepIndex: stepIndex,
				}));

				await navigate({
					to: '/cart/$step',
					params: {
						step: stepName,
					},
				});
			},
		});

		function gotoStep(step: StepName | number) {
			gotoStepMutation.mutate(step);
		}

		function gotoNextStep() {
			const nextStepIndex = store.state.currentStepIndex + 1;

			if (nextStepIndex >= steps.length) {
				log.warn('Cannot go to next step because the current step is the last step.');
			}

			gotoStep(getStepName(nextStepIndex));
		}

		function gotoPreviousStep() {
			const previousStepIndex = store.state.currentStepIndex - 1;

			if (previousStepIndex < 0) {
				log.warn('Cannot go to previous step because the current step is the first step.');
			}

			gotoStep(getStepName(previousStepIndex));
		}

		return {
			navigating: gotoStepMutation.isPending,
			gotoStep,
			gotoNextStep,
			gotoPreviousStep,
		};
	}

	function useStepValidities() {
		return useStore(store, x => x.valid);
	}

	function useStepValidity(stepName: StepName) {
		return useStore(store, x => x.valid[stepName]);
	}

	function useStep(stepName: StepName): StepHook {
		const valid = useStepValidity(stepName);

		return {
			index: steps.indexOf(stepName),
			name: stepName,
			valid,
			isConfirmationStep: stepName === 'confirmation',
		};
	}

	return {
		//state: store.state,
		steps,
		revalidate,
		useStepNavigation,
		useStepValidities,
		useStepValidity,
		useStep,
		getFirstInvalidStep,
		isStepValid,
	};
}
