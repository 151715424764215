import { z } from 'zod';

import { emptyOrNullableLocalDateTimeSchema } from '../portal/globalization.models';

export type Customer = z.infer<typeof customerSchema>;

export const customerIdSchema = z.number();

export const customerSchema = z.object({
	id: customerIdSchema.optional(),
	name: z.string().min(1),
	storeNumber: z.string(),
	streetAddress1: z.string(),
	streetAddress2: z.string().optional(),
	streetAddress3: z.string().optional(),
	city: z.string(),
	stateOrProvince: z.string(),
	postalCode: z.string(),
	countryCode: z.string(),
	arvatoCustomerCode: z.string(),
	customerGroupId: z.number(),
	customerClassificationId: z.number(),
	isDeleted: z.boolean(),
	activeFrom: emptyOrNullableLocalDateTimeSchema,
	activeUntil: emptyOrNullableLocalDateTimeSchema,
});
