import { Alert, Code, Stack } from '@mantine/core';
import { isError } from 'lodash';
import { z } from 'zod';

import { Warning } from '@apple/assets/icons';
import { getEnv } from '@apple/config/env';

const dev = getEnv().DEV;

export type ErrorFallbackProps = {
	error: unknown;
	componentStack?: string;
	onReset?: () => void;
};

export function ErrorFallback({ error, onReset, componentStack }: ErrorFallbackProps) {
	const errorName = isError(error) ? error.name : undefined;

	return (
		<Alert
			variant='light'
			color='red'
			radius='lg'
			m='lg'
			title={errorName}
			icon={<Warning />}
			withCloseButton
			onClose={onReset}
		>
			{isError(error) && (
				<Stack>
					{error.message}
					{dev && error instanceof z.ZodError && (
						<>
							<Code block>{JSON.stringify(error.issues, null, 4)}</Code>
							<Code block>{JSON.stringify(error.format(), null, 4)}</Code>
						</>
					)}
					{dev && componentStack && <Code block>{componentStack}</Code>}
					{dev && error.stack && <Code block>{error.stack}</Code>}
				</Stack>
			)}
		</Alert>
	);
}
