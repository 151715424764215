import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/tiptap/styles.css';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from '@tanstack/react-router';
import log from 'loglevel';

import { configApp } from '@apple/config';
import { AppRootProviders } from '@apple/contexts/app-root-providers';
import { useAuthContext } from '@apple/hooks/useAuthContext';
import { useCurrentUserLanguage } from '@apple/hooks/useCurrentUserLanguage';
import type { AppleRouter } from '@apple/config/router';

declare module '@tanstack/react-router' {
	interface Register {
		router: AppleRouter;
	}
}

async function run() {
	try {
		const { telemetry, router, env } = await configApp();

		// eslint-disable-next-line no-inner-declarations
		function App() {
			const auth = useAuthContext();
			const lang = useCurrentUserLanguage();

			return <RouterProvider router={router} context={{ auth, lang }} />;
		}

		const rootElement = document.getElementById('root');
		if (!rootElement) {
			throw new Error('Root element not found');
		}

		ReactDOM.createRoot(rootElement).render(
			env.APPLE_REACT_STRICT_MODE ? (
				<StrictMode>
					<AppRootProviders telemetry={telemetry} router={router}>
						<App />
					</AppRootProviders>
				</StrictMode>
			) : (
				<AppRootProviders telemetry={telemetry} router={router}>
					<App />
				</AppRootProviders>
			),
		);
	} catch (err) {
		log.error('Error starting app:', err);
	}
}

void run();
