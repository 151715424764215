import { Anchor, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useTranslation } from '@apple/utils/localization';

export type CartActionsProps = {
	itemsCount: number;
	onCreateCart: () => void;
	onViewCart: () => void;
};

export function CartActions({ itemsCount, onCreateCart, onViewCart }: CartActionsProps) {
	const { t } = useTranslation('shop');
	const [opened, { open, close }] = useDisclosure(false);

	function handleNewOrderClick() {
		open();
	}

	const handleResetCart = () => {
		onCreateCart();
	};

	const handleViewCart = () => {
		onViewCart();
	};

	return (
		<>
			<Modal opened={opened} onClose={close} title={t('cart.createModal.title')}>
				<Stack>
					<Text>{t('cart.createModal.description')}</Text>
					<Group justify='flex-end' wrap='nowrap'>
						<Button onClick={() => handleResetCart()}>
							{t('cart.createModal.continue')}
						</Button>
					</Group>
				</Stack>
			</Modal>
			<Group justify='center' mt='10'>
				<Stack>
					<Button
						onClick={() => {
							handleNewOrderClick();
						}}
					>
						{t('cart.labels.createOrder')}
					</Button>
					{itemsCount > 0 && (
						<Anchor ml='lg' onClick={handleViewCart}>
							{t('cart.labels.viewCart')}
						</Anchor>
					)}
				</Stack>
			</Group>
		</>
	);
}
