import { useMemo } from 'react';

import {
	Address,
	AdminPortal,
	Announcement,
	Calendar,
	Countries,
	CurrenciesIcon,
	Customers,
	FAQ,
	Home,
	LineOfBusiness,
	Location,
	LocationApproval,
	Merchandisers,
	OrderSearchIcon,
	Plant,
	Products,
	Programs,
	ProjectsIcon,
	ReturnsIcon,
	ShoppingBagOutline,
	SubPrograms,
	Success,
	SupportIcon,
	Upload,
	Users,
} from '@apple/assets/icons';
import { useAuthContext } from '@apple/hooks/useAuthContext';
import { useTranslation } from '@apple/utils/localization';
import type { MenuItem, MenuItemGroup } from '@apple/layouts/shared/menu-item';

export function useMainMenu() {
	const { t } = useTranslation('common');
	const { filterByPermissions } = useAuthContext();
	const menuItems = useMemo(() => {
		return filterByPermissions<MenuItem>([
			{
				label: t('mainMenu.shop'),
				icon: ShoppingBagOutline,
				to: '/cart',
				requireAuth: true,
			},
			{
				label: t('mainMenu.orders'),
				icon: OrderSearchIcon,
				to: '/orders/search',
				requireAuth: true,
			},
			{
				label: t('mainMenu.returns'),
				icon: ReturnsIcon,
				to: '/returns',
				requireAuth: true,
				requirePermission: 'AppleBrandedPrograms.Security.Features.Returns',
			},
			{
				label: t('mainMenu.help'),
				icon: SupportIcon,
				to: '/help',
				requireAuth: true,
			},
			{
				label: t('mainMenu.admin.label'),
				icon: AdminPortal,
				to: '/admin',
				requireAuth: true,
				// TODO: Create a specific permission for accessing the admin portal (users could have access to reports but not snapshot for example)
				requirePermission: 'Manzanita.Security.Features.Snapshot',
			},
		]);
	}, [filterByPermissions, t]);

	const adminMenuItems = useMemo(() => {
		return filterByPermissions<MenuItemGroup>([
			{
				requirePermission: 'Manzanita.Security.Features.Snapshot',
				items: filterByPermissions<MenuItem>([
					{
						icon: Home,
						label: t('mainMenu.admin.home.label'),
						to: '/admin',
						requirePermission: 'Manzanita.Security.Features.Snapshot',
					},
				]),
			},
			{
				label: t('mainMenu.admin.distroOrders.label'),
				requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
				items: filterByPermissions<MenuItem>([
					{
						icon: Upload,
						label: t('mainMenu.admin.distroOrders.upload'),
						to: '/distro/upload',
						requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
					},
					{
						icon: Calendar,
						label: t('mainMenu.admin.distroOrders.history'),
						to: '/distro/history',
						requirePermission: 'Manzanita.Security.Features.DistroOrderHistory',
					},
				]),
			},
			{
				label: t('mainMenu.admin.manage.label'),
				requirePermission: 'Manzanita.Security.Features.Snapshot',
				items: filterByPermissions<MenuItem>([
					{
						icon: Address,
						label: t('mainMenu.admin.manage.addresses'),
						to: '/manage/addresses',
						requirePermission:
							'AppleBranded.Security.ShipToAddress.CanEditOtherUsersAddresses',
					},
					{
						icon: Countries,
						label: t('mainMenu.admin.manage.countries'),
						to: '/manage/countries',
						requirePermission: 'Manzanita.Security.Features.CountryManagement.Read',
					},
					{
						icon: CurrenciesIcon,
						label: t('mainMenu.admin.manage.currencies'),
						to: '/manage/currencies',
						requirePermission: 'Manzanita.Security.Features.CustomerManagement',
					},
					{
						icon: LineOfBusiness,
						label: t('mainMenu.admin.manage.productLines'),
						to: '/manage/line-of-business',
						requirePermission:
							'Manzanita.Security.Features.ProductCategoryManagement.Read',
					},
					{
						icon: Location,
						label: t('mainMenu.admin.manage.locations'),
						to: '/manage/locations',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
					},
					{
						icon: Merchandisers,
						label: t('mainMenu.admin.manage.merchandisers'),
						to: '/manage/merchandisers',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
					},
					{
						icon: Plant,
						label: t('mainMenu.admin.manage.plants'),
						to: '/manage/plants',
						requirePermission: 'Manzanita.Security.Features.PlantManagement.Read',
					},
					{
						icon: Products,
						label: t('mainMenu.admin.manage.products'),
						to: '/manage/products',
						requirePermission: 'Manzanita.Security.Features.ProductManagement.Read',
					},
					{
						icon: Programs,
						label: t('mainMenu.admin.manage.programs'),
						to: '/manage/programs',
						requirePermission:
							'Manzanita.Security.Features.CustomerGroupManagement.Read',
					},
					{
						icon: Customers,
						label: t('mainMenu.admin.manage.customers'),
						to: '/manage/customers',
						requirePermission: 'Manzanita.Security.Features.CustomerManagement',
					},
					{
						icon: SubPrograms,
						label: t('mainMenu.admin.manage.subPrograms'),
						to: '/manage/subprograms',
						requirePermission:
							'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
					},
					{
						icon: Users,
						label: t('mainMenu.admin.manage.users'),
						to: '/manage/users',
						requirePermission: 'Manzanita.Security.Features.UserManagement.Read',
					},
				]),
			},
			{
				label: t('mainMenu.admin.content.label'),
				requirePermission: 'Manzanita.Security.Features.ContentManagement',
				items: filterByPermissions<MenuItem>([
					{
						icon: FAQ,
						label: t('mainMenu.admin.content.faq'),
						to: '/content/faq',
					},
					{
						icon: Announcement,
						label: t('mainMenu.admin.content.announcements'),
						to: '/content/announcements',
					},
				]),
			},
			{
				label: t('mainMenu.admin.approvals.label'),
				requireAuth: true,
				items: filterByPermissions<MenuItem>([
					{
						icon: Success,
						label: t('mainMenu.admin.approvals.orderApprovals'),
						to: '/approvals/orders',
						requirePermission: 'Manzanita.Security.Features.OrderManagement.Read',
					},
					{
						icon: LocationApproval,
						label: t('mainMenu.admin.approvals.locationApprovals'),
						to: '/approvals/location',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
					},
				]),
			},
			{
				label: t('mainMenu.admin.content.label'),
				requirePermission: 'Manzanita.Security.Features.ContentManagement',
				items: filterByPermissions<MenuItem>([
					{
						icon: ProjectsIcon,
						label: t('mainMenu.admin.content.projects'),
						to: '/content/projects',
					},
				]),
			},
			{
				label: t('mainMenu.admin.reports.label'),
				requirePermission: 'Manzanita.Security.Features.Reports',
				items: filterByPermissions<MenuItem>([
					{
						icon: Products,
						label: t('mainMenu.admin.manage.products'),
						to: '/reports/products',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: Users,
						label: t('mainMenu.admin.manage.users'),
						to: '/reports/users',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: SupportIcon,
						label: t('mainMenu.admin.reports.supportRequests'),
						to: '/reports/supportRequests',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
				]),
			},
		]);
	}, [filterByPermissions, t]);

	return { menuItems, adminMenuItems };
}
