import axios from 'axios';

import { approveOrdersResponseSchema } from './approval.models';
import type {
	ApprovalDetailResponse,
	ApproveOrdersRequest,
	ApproveOrdersResponse,
	ApproveQuantityUpdateResponse,
	OrderApprovalListing,
	PurchaseOrderUpdate,
	RejectOrdersRequest,
	ReviewOrdersRequest,
} from './approval.models';

export async function getApprovalOrders(signal?: AbortSignal): Promise<OrderApprovalListing[]> {
	const response = await axios.get<OrderApprovalListing[]>('/api/approval-queue', { signal });

	return response.data;
}

export async function approveOrders(request: ApproveOrdersRequest): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/approve-orders',
		request,
	);

	return response.data;
}

export async function rejectOrders(request: RejectOrdersRequest): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/reject-orders',
		request,
	);

	return response.data;
}

export async function markForReviewOrders(
	request: ReviewOrdersRequest,
): Promise<ApproveOrdersResponse> {
	const response = await axios.post<ApproveOrdersResponse>(
		'/api/approval-queue/review-orders',
		request,
	);

	return response.data;
}

export async function getApprovalDetail(
	poNumber: string,
	signal?: AbortSignal,
): Promise<ApprovalDetailResponse> {
	const response = await axios.get<ApprovalDetailResponse>(
		`/api/approval-queue/${poNumber}/approval-details`,
		{ signal },
	);

	return response.data;
}

export async function evaluateRules(
	request: PurchaseOrderUpdate,
): Promise<ApproveQuantityUpdateResponse> {
	const response = await axios.post<ApproveQuantityUpdateResponse>(
		'/api/approval-queue/orders/evaluate-rules',
		request,
	);

	return response.data;
}

export async function saveOrder(request: PurchaseOrderUpdate): Promise<ApproveOrdersResponse> {
	const data = (await axios.post<unknown>('/api/approval-queue/orders', request)).data;
	return approveOrdersResponseSchema.parse(data);
}
