import axios from 'axios';

import { productVisibilityRolesSchema, roleSchema } from './roles.models';
import type { ProductVisibilityRoles, Role } from '../auth/roles.models';

/** Gets a collection of all supported user roles. */
export async function getRoles(): Promise<Role[]> {
	const response = await axios.get('/api/user-roles');
	return roleSchema.array().parse(response.data);
}

export async function getProductVisibilityRoles(): Promise<ProductVisibilityRoles> {
	const response = await axios.get('/api/user-roles/product-visibility');

	return productVisibilityRolesSchema.parse(response.data);
}
