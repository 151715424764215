import { z } from 'zod';

import {
	countryCodeSchema,
	emptyOrNullableLocalDateTimeSchema,
} from '@apple/api/portal/globalization.models';
import { programIdSchema } from '@apple/api/program/models';
import type { ProgramId } from '@apple/api/program/models';

export interface ProgramListing extends Program {
	code: string;
	customerCount: number;
	status: string;
}

export interface ProgramRequest extends Program {
	arvatoCustomerGroupCode: string;
	countryCode: string | null;
	description: string | null;
	isDeleted: boolean;
	logoImageUrl: string | null;
}

export interface Program {
	id: ProgramId;
	name: string;
	activeDate: '' | Date | null;
	inactiveDate: '' | Date | null;
}

export const programSchema = z.object({
	id: programIdSchema,
	name: z.string(),
	activeDate: emptyOrNullableLocalDateTimeSchema,
	inactiveDate: emptyOrNullableLocalDateTimeSchema,
}) as z.ZodType<Program>;

export const programRequestSchema = programSchema.and(
	z.object({
		arvatoCustomerGroupCode: z.string(),
		countryCode: countryCodeSchema.nullable(),
		description: z.string().nullable(),
		isDeleted: z.boolean(),
		logoImageUrl: z.string().nullable(),
	}),
) as z.ZodType<ProgramRequest>;

export const programListingSchema = programSchema.and(
	z.object({
		code: z.string(),
		customerCount: z.number(),
		status: z.string(),
	}),
) as z.ZodType<ProgramListing>;
