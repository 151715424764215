import { memo } from 'react';
import { Burger, Group, useMantineTheme } from '@mantine/core';
import type { MantineBreakpoint } from '@mantine/core';

import { AppleLogo } from '@apple/components/AppleLogo';
import { getEnv } from '@apple/config/env';
import { TopbarNavMenu } from '@apple/layouts/shared/topbar-nav-menu';
import { TopbarUserMenu } from '@apple/layouts/shared/topbar-user-menu';
import type { MenuItem } from '@apple/layouts/shared/menu-item';

const env = getEnv();

export const AppleHeader = memo(
	({
		globalMenuItems,
		sidebarOpen,
		toggleSidebar,
		sidebarCollapseBreakpoint,
	}: {
		globalMenuItems: MenuItem[];
		sidebarOpen: boolean;
		toggleSidebar: () => void;
		sidebarCollapseBreakpoint: MantineBreakpoint;
	}) => {
		const theme = useMantineTheme();

		return (
			<Group
				w='100%'
				h={theme.other.headerHeight}
				justify='space-between'
				align='center'
				wrap='nowrap'
			>
				<Group>
					<AppleLogo to='/' label={env.APPLE_APP_TITLE} size={24} />
				</Group>

				<Group>
					<TopbarNavMenu
						items={globalMenuItems}
						visibleFrom={sidebarCollapseBreakpoint}
					/>
				</Group>

				<Group>
					<TopbarUserMenu visibleFrom={sidebarCollapseBreakpoint} variant='light' />
					<Burger
						opened={sidebarOpen}
						onClick={toggleSidebar}
						hiddenFrom={sidebarCollapseBreakpoint}
						size='sm'
					/>
				</Group>
			</Group>
		);
	},
);

AppleHeader.displayName = 'AppleHeader';
