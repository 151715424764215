import axios from 'axios';
import type { PaginationState as TanstackPaginationState } from '@tanstack/react-table';

import { createODataPageResultSchema } from '@apple/utils/odata';

import { getPageState } from '../pagination.models';
import { allowedOrderSearchFiltersSchema, orderSearchListingSchema } from './search.models';
import type { PageState } from '../pagination.models';
import type {
	AllowedOrderSearchFilters,
	OrderSearchFilters,
	OrderSearchListing,
} from './search.models';

const orderSearchODataSchema =
	createODataPageResultSchema<OrderSearchListing>(orderSearchListingSchema);

export async function getOrderSearchResults(
	pagination: TanstackPaginationState,
	filters: OrderSearchFilters,
): Promise<PageState<OrderSearchListing>> {
	const response = (
		await axios.get<unknown>('/api/order-search', {
			params: {
				skip: pagination.pageIndex * pagination.pageSize,
				take: pagination.pageSize,
				filter: filters,
			},
		})
	).data;

	const result = orderSearchODataSchema.parse(response);

	return getPageState<OrderSearchListing>({
		rowSchema: orderSearchListingSchema,
		state: pagination,
		rows: result.items,
		totalRowCount: result.count ?? 0,
	});
}

export async function getAllowedOrderSearchFields(): Promise<AllowedOrderSearchFilters> {
	const data = (await axios.get<unknown>('/api/order-search/options')).data;
	return allowedOrderSearchFiltersSchema.parse(data);
}

export function getOrderSearchExportUrl() {
	return '/api/order-search/export';
}
