import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getCountries, getProfile } from '../auth';

export const profileQueryOptions = (enabled: boolean = true) =>
	queryOptions({
		queryKey: ['profile'],
		queryFn: () => getProfile(),
		refetchOnMount: true,
		// Prevents page flicker when navigating since this is used in `beforeLoad`
		placeholderData: keepPreviousData,
		enabled: enabled,
	});

export const profileCountriesQueryOptions = queryOptions({
	queryKey: ['profile', 'countries'],
	queryFn: () => getCountries(),
});
