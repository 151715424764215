import { ActionIcon, Group, rem, Title } from '@mantine/core';
import { Link } from '@tanstack/react-router';
import type { ToOptions } from '@tanstack/react-router';

import { AppleLogo as AppleLogoIcon } from '@apple/assets/icons';

export type AppleLogoProps = { size?: string | number; label?: string; to?: ToOptions['to'] };

export function AppleLogo({ size = 24, label, ...toProps }: AppleLogoProps) {
	function Logo() {
		if (label) {
			return (
				<Group w='100%' wrap='nowrap' gap={rem(6)} align='center' justify='left'>
					<AppleLogoIcon size={size} width={size} height={size} />
					<Title order={1} size='sm' fw={600}>
						{label}
					</Title>
				</Group>
			);
		}

		return <AppleLogoIcon size={size} />;
	}

	if (toProps.to === undefined) {
		return <Logo />;
	}

	return (
		<ActionIcon
			component={Link}
			{...toProps}
			variant='transparent'
			color='rgba(255, 255, 255, 1)'
			aria-label={label ?? 'Apple Logo'}
			size={size}
			w='100%'
		>
			<Logo />
		</ActionIcon>
	);
}
