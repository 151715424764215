import axios from 'axios';

import { productLineSchema } from '../cart/product-line.models';
import type { ProductLine } from '../cart/product-line.models';
import type { PaginatedListDto } from '../pagination.models';

export async function getById(id: number): Promise<ProductLine> {
	// TODO: These are actually product categories
	const response = await axios.get(`/api/management/product-categories/${id}`);

	return productLineSchema.parse(response.data);
}

/** Get a list of all product lines of business. */
export async function getProductLineGridData(): Promise<ProductLine[]> {
	// TODO: Zod schema for pagination - also this is technically an odata api for categories
	const response = await axios.get<PaginatedListDto<ProductLine>>(
		'/odata/management/product-categories',
	);

	return response.data.items;
}

export async function getProductLines(): Promise<ProductLine[]> {
	const response = await axios.get('/api/line-of-business/');

	return productLineSchema.array().parse(response.data);
}
