import { MantineProvider, MantineThemeContext } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider } from '@tanstack/react-query';
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type { PropsWithChildren } from 'react';

import { queryClient } from '@apple/config/router';
import { AuthProvider } from '@apple/contexts/auth';
import { CartIdProvider } from '@apple/contexts/cart';
import { appleThemeOverrides, cssVariablesResolver } from '@apple/styles/apple-theme';
import type { AppleRouter } from '@apple/config/router';

type AppRootProvidersProps = PropsWithChildren<{
	telemetry: ReactPlugin;
	router: AppleRouter;
}>;

/** The app root providers are rendered outside of the routing provider */
export function AppRootProviders({ telemetry, router, children }: AppRootProvidersProps) {
	return (
		<AppInsightsContext.Provider value={telemetry}>
			<QueryClientProvider client={queryClient}>
				<MantineProvider
					theme={appleThemeOverrides}
					cssVariablesResolver={cssVariablesResolver}
					defaultColorScheme='auto'
				>
					<MantineThemeContext.Consumer>
						{theme => (
							<>
								<Notifications
									position='top-right'
									mt={theme?.other.headerHeight}
								/>
								<ModalsProvider>
									<AuthProvider router={router}>
										<CartIdProvider>{children}</CartIdProvider>
									</AuthProvider>
								</ModalsProvider>
							</>
						)}
					</MantineThemeContext.Consumer>
				</MantineProvider>
			</QueryClientProvider>
		</AppInsightsContext.Provider>
	);
}
