import { z } from 'zod';

import type { CountryCode } from '@apple/types/globalization';

import { countryCodeSchema, dateTimeSchema } from '../portal/globalization.models';

export type Program = z.infer<typeof programSchema>;
export type ProgramId = z.infer<typeof programIdSchema>;
export type ProgramStatus = z.infer<typeof programStatusSchema>;

export interface ProgramFilters {
	countryCode?: string;
	searchTerm?: string;
	includeInactive?: boolean;
}

export interface LocationProgramListing {
	id: ProgramId;
	name: string;
	code: string;
	countries: CountryCode[];
	logoImageUrl: string;
	activeDate: moment.Moment | null;
	inactiveDate: moment.Moment | null;
	isDeleted: boolean;
	description: string;
}

export const programIdSchema = z.number();
export const programStatusSchema = z.enum(['Active', 'Inactive']);
export const programSchema = z.object({
	id: programIdSchema,
	name: z.string(),
	code: z.string(),
	status: programStatusSchema.nullable(),
	countries: z.array(countryCodeSchema).default([]),
	logoImageUrl: z.string().nullable(),
	activeDate: dateTimeSchema.nullable(),
	inactiveDate: dateTimeSchema.nullable(),
	isDeleted: z.boolean(),
	description: z.string().nullable(),
});

export const programFiltersSchema = z.object({
	countryCode: z.string().optional(),
	searchTerm: z.string().optional(),
	includeInactive: z.boolean().optional(),
}) satisfies z.ZodType<ProgramFilters>;
