import { forwardRef, memo } from 'react';
import { Anchor, Box } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { clsx } from 'clsx';
import type { AnchorProps } from '@mantine/core';
import type { ToOptions } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';

import classes from './Link.module.css';

export type LinkProps = PropsWithChildren<
	Omit<AnchorProps, 'to'> & {
		to?: ToOptions['to'];
		className?: string;
		label?: string;
		title?: string;
		icon?: React.ReactNode;
	}
>;

export const Link = memo(createLink(forwardRef(_Link)));
Link.displayName = 'Link';

function _Link(
	{ label, icon, children, className, ...anchorProps }: LinkProps,
	ref: React.Ref<HTMLAnchorElement>,
) {
	if (!icon && label) {
		return (
			<Anchor ref={ref} {...anchorProps} className={clsx(classes.routerLink, className)}>
				{label}
			</Anchor>
		);
	}

	if (icon !== undefined && label !== undefined) {
		return (
			<Anchor ref={ref} {...anchorProps} className={clsx(classes.routerLink, className)}>
				<Box component='span' mr={5}>
					{label}
				</Box>
				{icon}
			</Anchor>
		);
	}

	return (
		<Anchor ref={ref} {...anchorProps} className={clsx(classes.routerLink, className)}>
			{icon}
			{children}
		</Anchor>
	);
}
