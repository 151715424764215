import { LoadingOverlay as MantineLoadingOverlay } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import type { LoadingOverlayProps as MantineLoadingOverlayProps } from '@mantine/core';

export type LoadingOverlayProps = MantineLoadingOverlayProps & {
	debounce?: number;
};

export function LoadingOverlay(props: LoadingOverlayProps) {
	const [debouncedVisible] = useDebouncedValue(props.visible, props.debounce ?? 0);
	// Debounce only on `true` values, `false` values should be shown immediately
	return <MantineLoadingOverlay {...props} visible={debouncedVisible && props.visible} />;
}
