import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import type { QueryOptions } from '@apple/types/tanstack-query';

import { getComments, getPurchaseOrder, getPurchaseOrderLines } from './details';
import { getAllowedOrderSearchFields } from './search';
import type { Comment, PurchaseOrderLine, PurchaseOrderResponse } from './details.models';
import type { AllowedOrderSearchFilters } from './search.models';

export const purchaseOrderQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber],
		queryFn: () => getPurchaseOrder(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<PurchaseOrderResponse>;

export const purchaseOrderLinesQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'lines'],
		queryFn: () => getPurchaseOrderLines(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<PurchaseOrderLine[]>;

export const commentsQueryOptions = (poNumber: string) =>
	queryOptions({
		queryKey: ['purchase-order', poNumber, 'comments'],
		queryFn: () => getComments(poNumber),
		staleTime: 5 * 60 * 1000, // 5 minute
	}) as QueryOptions<Comment[]>;

export const allowedOrderSearchFieldsQueryOptions = queryOptions({
	queryKey: ['order-search-options'],
	queryFn: () => getAllowedOrderSearchFields(),
	placeholderData: keepPreviousData,
	staleTime: 5 * 60 * 1000, // 5 minutes
}) as QueryOptions<AllowedOrderSearchFilters>;
