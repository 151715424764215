import axios from 'axios';

import { approvalAccessRequestsListItemSchema } from '@apple/api/location/location-approval.models';
import { locationRequestResponseSchema } from '@apple/api/location/location.models';
import type { LocationAccessRequest } from '@apple/api/location/location-approval.models';
import type { LocationId, LocationRequestResponse } from '@apple/api/location/location.models';

export async function getLocationApprovalQueue(
	signal?: AbortSignal,
): Promise<LocationAccessRequest[]> {
	const response = await axios.get('/api/location-approval-queue/list', {
		signal,
	});

	return approvalAccessRequestsListItemSchema.array().parse(response.data);
}

export async function getLocationAccessRequest(
	userId: number,
	signal?: AbortSignal,
): Promise<LocationRequestResponse> {
	const response = await axios.get(`/api/location-approval-queue/requests/${userId}`, {
		signal,
	});
	return locationRequestResponseSchema.parse(response.data);
}

export async function completeLocationAccessRequests(
	locationIds: LocationId[],
	approve: boolean,
	userId: number,
): Promise<void> {
	await axios.post('/api/location-approval-queue/requests/complete', {
		locationIds,
		approve,
		userId,
	});
}
