import axios, { AxiosError } from 'axios';

import { ServerError } from '../errors';
import { keywordSchema } from '../product/management.models';
import type { Keyword, NewKeyword } from '../product/management.models';

/** Gets all product keywords. */
export async function getKeywords(): Promise<Keyword[]> {
	const response = await axios.get('/api/management/product-keywords');

	return keywordSchema.array().parse(response.data);
}

/** Gets the product keyword with the given ID. */
export async function getKeywordById(keywordId: number): Promise<Keyword | null> {
	try {
		const response = await axios.get(`/api/management/product-keywords/${keywordId}`);
		return keywordSchema.parse(response.data);
	} catch (error) {
		if (
			error instanceof ServerError &&
			error.innerError instanceof AxiosError &&
			error.innerError.status === 404
		) {
			return null;
		}
		throw error;
	}
}

/** Saves the given keyword. */
export async function saveKeyword(keyword: NewKeyword): Promise<Keyword> {
	const response = await axios.post('/api/management/product-keywords', keyword);

	return keywordSchema.parse(response.data);
}
