import axios, { isAxiosError } from 'axios';
import log from 'loglevel';

import type { Country } from '@apple/types/globalization';

import { isLoginFailedResponse, isLoginSuccessResponse, loginRequestSchema } from '../auth/models';
import { NotAuthenticatedError, ServerError } from '../errors';
import type { LoginRequest, LoginSuccessResponse } from '../auth/models';
import type { ChangePasswordRequest, ProfileDto, UpdateProfileRequest } from '../auth/user.models';
import type { Result } from '../types';

export async function login(request: LoginRequest): Promise<LoginSuccessResponse> {
	// Validate the request object
	request = loginRequestSchema.parse(request);

	// Cookie Auth
	// Returns 200 with { result: true, redirectUrl?: string } if successful
	// Returns 200 with { result: false, message?: string } if failed
	const response = await axios.post('/api/authentication/login', request, {
		withCredentials: true,
		// Don't follow server redirects because they point to the legacy pages
		maxRedirects: 0,
	});

	if (isLoginSuccessResponse(response.data)) {
		// Retrieve xsrf token for new user
		await getXsrfToken();
		return response.data;
	}

	if (isLoginFailedResponse(response.data)) {
		throw new ServerError(response.data.message, {
			cause: response.data.errorCode,
		});
	}

	log.error('Unexpected response from the server', response);
	throw new ServerError('Failed to login.');
}

export async function logout() {
	try {
		await axios.post('/api/authentication/logout', undefined, {
			// Don't follow server redirects because they point to the legacy pages
			maxRedirects: 0,
			// Don't throw an error if the server returns an error
			validateStatus: () => false,
		});
		// Retrieve xsrf token for anonymous user
		await getXsrfToken();
	} catch (err) {
		// TODO: Handle the response object from the server
		// https://github.com/aspnet/AspNetKatana/blob/43996b47015ca0c0ad12cdb6c87a017534eec620/src/Microsoft.Owin.Security.Cookies/Provider/DefaultBehavior.cs#L27
		log.warn('Ignoring server error which occurred while attempting to logout.', err);
	}
}

export async function getProfile(): Promise<ProfileDto | null> {
	try {
		return (await axios.get<ProfileDto>('/api/profile')).data;
	} catch (error) {
		if (error instanceof NotAuthenticatedError) {
			return null;
		}

		if (
			error instanceof ServerError &&
			isAxiosError(error.innerError) &&
			error.innerError.status === 401
		) {
			return null;
		}

		if (isAxiosError(error) && error.response?.status === 401) {
			return null;
		}

		throw error;
	}
}

export async function getXsrfToken(): Promise<boolean> {
	try {
		await axios.get('/api/authentication/xsrf-token');
		return true;
	} catch (error) {
		log.error('Unable to get token.', error);
		throw error;
	}
}

/** See: AuthenticationController.RetrievePassword */
export async function resetPassword(username: string): Promise<void> {
	await axios.post<string>('/api/authentication/reset-password', {
		userName: username,
	});
}

/** See: AuthenticationController.RetrieveUsername */
export async function sendForgotUsernameEmail(email: string): Promise<void> {
	await axios.post('/api/authentication/retrieve-username', { email });
}

export async function updateProfile(
	request: UpdateProfileRequest,
): Promise<Result<string, ProfileDto>> {
	const response = await axios.post<ProfileDto>('/api/profile', request);

	return { success: true, data: response.data };
}

export async function changePassword(request: ChangePasswordRequest): Promise<void> {
	await axios.post('/api/profile/password/change', request);
}

/** Gets a list of countres that the current user has access to */
export async function getCountries() {
	return (await axios.get<Country[]>('/api/profile/countries')).data;
}
