import { queryOptions } from '@tanstack/react-query';

import { getXsrfToken } from '../auth';

export const xsrfQueryOptions = (withRefetchInterval: boolean = false) =>
	queryOptions({
		queryKey: ['xsrf', withRefetchInterval],
		queryFn: getXsrfToken,
		staleTime: Infinity,
		refetchInterval: withRefetchInterval ? 30 * 1000 : false,
		retry: !withRefetchInterval ? 1 : 0,
	});
