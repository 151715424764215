import { QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

import { RouterErrorFallback } from '@apple/components/ErrorFallback';
import { LoadingOverlay } from '@apple/components/LoadingOverlay';
import { NotFoundPage } from '@apple/pages/NotFoundPage';
import { routeTree } from '@apple/routes.gen';

export type AppleRouter = typeof router;

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

export const router = createRouter({
	routeTree,
	defaultPreload: false,
	defaultPendingComponent: () => <LoadingOverlay visible />,
	defaultNotFoundComponent: NotFoundPage,
	defaultErrorComponent: RouterErrorFallback,
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	// https://tanstack.com/router/latest/docs/framework/react/guide/data-loading#passing-all-loader-events-to-an-external-cache
	defaultPreloadStaleTime: 0,
	//Wrap: ({ children }) => <AppProviders>{children}</AppProviders>,
	defaultPendingMs: 300,
	context: {
		queryClient,
		auth: undefined!, // This will be set after we wrap the app in an AuthProvider
		lang: undefined!, // This will be set after we wrap the app in a LangProvider
	},
});
