import { getLogger, default as rootLogger } from 'loglevel';
import * as prefix from 'loglevel-plugin-prefix';
import type { Logger, LogLevelDesc, RootLogger } from 'loglevel';

prefix.reg(rootLogger);
rootLogger.enableAll();
rootLogger.rebuild();

export function configLogging(
	defaultLevel: LogLevelDesc,
	loggerLevels?: Record<string, LogLevelDesc | true>,
): RootLogger {
	setupLogger(rootLogger, defaultLevel);

	if (loggerLevels) {
		for (const [logger, level] of Object.entries(loggerLevels)) {
			if (level === true) {
				setupLogger(logger, defaultLevel);
			} else {
				setupLogger(logger, level);
			}
		}
	}

	return rootLogger;
}

function setupLogger(logger: string | Logger, level: LogLevelDesc): Logger {
	const instance = typeof logger === 'string' ? getLogger(logger) : logger;

	instance.setLevel(level);
	return prefix.apply(instance, {
		format: (level, name) => `${level} (${name}):`,
	});
}

/*
Logging Plugins:
- https://github.com/kutuluk/loglevel-plugin-prefix
- https://github.com/kutuluk/loglevel-plugin-remote
*/
